import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonResponse, CommonPaginateResponse } from '../api-response.dto';
import { Observable } from 'rxjs';
import { Select2OptionData } from 'ng-select2';
import { RegisterAgent } from '../../shared/dto/auth.dto';

@Injectable({
  providedIn: 'root',
})
export class BranchManagementServices {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  };

  getData(param: any) {
    return this.http.get<CommonPaginateResponse>(environment.apiUrl + '/branch/pagination', {
      params: param,
    });
  }

  getDetailBranch(uuid: any) {
    return this.http.get<CommonPaginateResponse>(`${environment.apiUrl}/branch/${uuid}`, {
      params: {},
    });
  }

  getUnverifiedData(param: any) {
    return this.http.get<CommonPaginateResponse>(environment.apiUrl + '/branch/unverified', {
      params: param,
    });
  }

  getUnverifiedDataPagination(param: any) {
    return this.http.get<CommonPaginateResponse>(environment.apiUrl + '/branch/unverified/pagination', {
      params: param,
    });
  }

  verifiedBranch(uuid: string) {
    return this.http.put<CommonResponse>(`${environment.apiUrl}/branch/verified/${uuid}`,
        {},
        this.httpOptions
      );
  }

  addBranch(param: RegisterAgent) {
    return this.http.post<CommonResponse>(`${environment.apiUrl}/branch/register-branch`,
        param,
        this.httpOptions
      );
  }

  getBranchSelect(param: any) {
    return this.http.get<CommonResponse>(environment.apiUrl + '/branch', {
      params: param,
    });
  }

  getCustomerBranchSelect(param: any): Observable<Array<Select2OptionData>> {
    return new Observable((obs) => {
      this.http.get<CommonResponse>(environment.apiUrl + '/customer/branch', { params: param })
      .subscribe(
        (data) => {
          const responseData = data.data;
          const displayData: Array<Select2OptionData> = [];

          displayData.push({
            text: 'Choose Branch',
            id: '',
          });
          responseData.map((e) => {
            displayData.push({
              text: `${String(e.branchCode)} - ${String(e.bussinessName)}`,
              id: String(e.uuid),
            });
          });
          obs.next(displayData);
          obs.complete();
        }
      );
    });
  }

  getBranchrekening(param: any) {
    return this.http.get<CommonResponse>(environment.apiUrl + '/branchrekening', {
      params: param,
    });
  }
}
