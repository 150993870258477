import { RouteInfo } from './sidebar.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: 'dashboard/',
    title: 'Dashboard',
    icon: 'bx bx-home-circle',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    menuKey: 'dashboard',
    submenu: [],
  },
  {
    path: '',
    title: 'Accounting',
    icon: 'bx bx-book',
    class: 'sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    menuKey: '',
    submenu: [
      {
        path: 'accounting/manage-branch-balance',
        title: 'Manage Branch Balance',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'accounting-inputBalanceBranch',
        submenu: [],
      },
      {
        path: 'accounting/manage-operator-balance',
        title: 'Manage Operator Balance',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'accounting-inputBalanceOperator',
        submenu: [],
      },
      {
        path: 'accounting/manage-mainprovider-balance',
        title: 'Average Rate Calculator',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'accounting-inputBalanceMainProvider',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Administration',
    icon: 'bx bx-wrench',
    class: 'sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    menuKey: '',
    submenu: [
      {
        path: 'javascript:;',
        title: 'Customer Management',
        icon: 'bx bx-right-arrow-alt',
        class: 'sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: '',
        submenu: [
          {
            path: 'administration/customer-add',
            title: 'Add Customer',
            icon: 'bx bx-chevron-right',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            menuKey: 'administration-customerAdd',
            submenu: [],
          },
          {
            path: 'administration/customers',
            title: 'List Customer',
            icon: 'bx bx-chevron-right',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            menuKey: 'administration-customerList',
            submenu: [],
          },
          {
            path: 'administration/customer-approval',
            title: 'Customer Approval',
            icon: 'bx bx-chevron-right',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            menuKey: 'administration-customerApproval',
            submenu: [],
          },
        ],
      },
      {
        path: 'administration/branches',
        title: 'Branch Management',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'administration-branchManagement',
        submenu: [],
      },
      {
        path: 'javascript:;',
        title: 'Rate Management',
        icon: 'bx bx-right-arrow-alt',
        class: 'sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'administration-dailyRateSpesificBranch',
        submenu: [
          {
            path: 'administration/daily-rate/currency',
            title: 'Currency',
            icon: 'bx bx-chevron-right',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            menuKey: 'administration-dailyRateSpesificBranch',
            submenu: [],
          },
          {
            path: 'administration/daily-rate',
            title: 'Daily Rate',
            icon: 'bx bx-chevron-right',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            menuKey: 'administration-dailyRateSpesificBranch',
            submenu: [],
          },
          {
            path: 'administration/daily-rate/minimum-rate',
            title: 'Starting Rate',
            icon: 'bx bx-chevron-right',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            menuKey: 'administration-minimumRate',
            submenu: [],
          },
        ],
      },
      {
        path: 'administration/service-charge',
        title: 'Service Charge',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'administration-serviceChargeManagement',
        submenu: [],
      },
      {
        path: 'administration/manage-blacklist',
        title: 'Blacklist',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'administration-blackListManagement',
        submenu: [],
      },
      {
        path: 'administration/agents',
        title: 'Agent List',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: '',
        submenu: [],
      },
      {
        path: 'administration/user-management',
        title: 'User Management',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'administration-userManagement',
        submenu: [],
      },
      {
        path: 'administration/menu-management',
        title: 'Menu Management',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'administration-MenuManagement',
        submenu: [],
      },
      {
        path: 'administration/branch-sender-limit',
        title: 'Branch Sender Limit',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'administration-branch-sender-limit',
        submenu: [],
      },
      {
        path: 'administration/branch-rekening',
        title: 'Branch Rekening',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'administration-branch-rekening',
        submenu: [],
      },
      {
        path: 'administration/expired-ic',
        title: 'Expired IC Soon',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'administration-expired-ic',
        submenu: [],
      }
    ],
  },
  {
    path: 'payment',
    title: 'Digital Payment',
    icon: 'bx bxs-wallet',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    menuKey: 'remittance-new',
    submenu: [],
  },
  {
    path: '',
    title: 'Remittance',
    icon: 'bx bx-transfer',
    class: 'sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    menuKey: 'remittance',
    submenu: [
      {
        path: 'remittance/list',
        title: 'List Remittance',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'remittance-list',
        submenu: [],
      },
      {
        path: 'remittance/new',
        title: 'New Remittance',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'remittance-new',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Remittance Customer',
    icon: 'bx bxs-user-account',
    class: 'sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    menuKey: 'remittance',
    submenu: [
      {
        path: 'remittance-customer/transaction-list',
        title: 'List Customer Transaction',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'remittance-transaction-list',
        submenu: [],
      },
      {
        path: 'remittance-customer/transaction-request',
        title: 'List Request Remittance',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'remittance-list',
        submenu: [],
      },
      {
        path: 'remittance-customer/scan-barcode',
        title: 'Scan Barcode',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'remittance-barcode',
        submenu: [],
      },
      {
        path: 'remittance-customer/confirmation',
        title: 'Confirmation Transfer',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'remittance-confirmTransfer',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Reports',
    icon: 'bx bx-line-chart',
    class: 'sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    menuKey: 'report',
    submenu: [
      {
        path: 'reports/daily-report-operator',
        title: 'Daily Report by Operator',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'report-dailyReportOperator',
        submenu: [],
      },
      {
        path: 'reports/monthly-report',
        title: 'Monthly Report',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'report-monthlyReport',
        submenu: [],
      },
      {
        path: 'reports/finance-supervisor-report',
        title: 'Finance Supervisor Report',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'report-financeReport',
        submenu: [],
      },
      {
        path: 'reports/report-by-country',
        title: 'Report By Country',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'report-country',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Master Data',
    icon: 'bx bx-data',
    class: 'sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    menuKey: '',
    submenu: [
      {
        path: 'javascript:;',
        title: 'Data Region',
        icon: 'bx bx-right-arrow-alt',
        class: 'sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: '',
        submenu: [
          {
            path: 'master-data/area',
            title: 'Area',
            icon: 'bx bx-chevron-right',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            menuKey: 'master-area',
            submenu: [],
          },
          {
            path: 'master-data/country',
            title: 'Country',
            icon: 'bx bx-chevron-right',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            menuKey: 'master-country',
            submenu: [],
          },
          {
            path: 'master-data/province',
            title: 'Provinces',
            icon: 'bx bx-chevron-right',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            menuKey: 'master-province',
            submenu: [],
          },
          {
            path: 'master-data/cities',
            title: 'Cities',
            icon: 'bx bx-chevron-right',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            menuKey: 'master-city',
            submenu: [],
          },
          {
            path: 'master-data/regencies',
            title: 'Regencies',
            icon: 'bx bx-chevron-right',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            menuKey: 'master-regency',
            submenu: [],
          },
          {
            path: 'master-data/states',
            title: 'States',
            icon: 'bx bx-chevron-right',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            menuKey: 'master-state',
            submenu: [],
          },
        ],
      },
      {
        path: 'master-data/bank',
        title: 'Bank',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'master-bank',
        submenu: [],
      },
      {
        path: 'master-data/purpose',
        title: 'Purpose',
        icon: 'bx bx-right-arrow-alt',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        menuKey: 'master-purpose',
        submenu: [],
      },
    ],
  },
];
