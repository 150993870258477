<table style="width: 100%; font-size: 9px !important;">
  <tbody>
    <tr>
      <td colspan="2">
        <span style="font-size: 11px; font-weight: 700;">Remittance Receipt</span>
      </td>
    </tr>
    <tr>
      <td style="width: 30%;">No.</td>
      <td>: {{ remittanceDetail.generalInformation?.remittanceNumber }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">GMT Date</td>
      <td>: {{ dateFormatService.longFormatDateWithTimeNoTz(remittanceDetail.generalInformation?.gmtDate) }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Local Date</td>
      <td>: {{ dateFormatService.longFormatDateWithTimeUsingTz(remittanceDetail.generalInformation?.localDate) }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Agent Reff</td>
      <td>: -</td>
    </tr>
    <tr>
      <td style="width: 30%;">Provider</td>
      <td>: {{ remittanceDetail.generalInformation?.provider.name }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Product Category</td>
      <td>: {{ remittanceDetail.generalInformation?.product.category }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Product</td>
      <td>: {{ remittanceDetail.generalInformation?.product.name }}</td>
    </tr>
  </tbody>
</table>
<table style="width: 100%; font-size: 9px !important;">
  <tbody>
    <tr>
      <td colspan="2">
        <span style="font-size: 11px; font-weight: 700;">Sender Information</span>
      </td>
    </tr>
    <tr>
      <td style="width: 30%;">Customer</td>
      <td>: {{ remittanceDetail.senderInformation?.cName }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Customer ID</td>
      <td>: {{ remittanceDetail.senderInformation?.cCode }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Address</td>
      <td>: {{ remittanceDetail.senderInformation?.cAddress }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Country</td>
      <td>: {{ remittanceDetail.senderInformation?.cCountry }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Phone</td>
      <td>: {{ remittanceDetail.senderInformation?.cPhone }}</td>
    </tr>
  </tbody>
</table>
<table style="width: 100%; font-size: 9px !important;">
  <tbody>
    <tr>
      <td colspan="2">
        <span style="font-size: 11px; font-weight: 700;">Beneficiary Information</span>
      </td>
    </tr>
    <tr>
      <td style="width: 30%;">Beneficiery</td>
      <td>: {{ remittanceDetail.beneficiaryInformation?.bName }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Phone</td>
      <td>: {{ remittanceDetail.beneficiaryInformation?.bPhone }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Destination Account</td>
      <td>: {{ remittanceDetail.beneficiaryInformation?.bAccountNo }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Bank</td>
      <td>: {{ remittanceDetail.beneficiaryInformation?.bank }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Branch</td>
      <td>: {{ remittanceDetail.beneficiaryInformation?.branch }}</td>
    </tr>
  </tbody>
</table>
<table style="width: 100%; font-size: 9px !important;">
  <tbody>
    <tr>
      <td colspan="2">
        <span style="font-size: 11px; font-weight: 700;">Transaction Information</span>
      </td>
    </tr>
    <tr>
      <td style="width: 30%;">Transfer From</td>
      <td>: {{ remittanceDetail.transactionInformation?.currencyFrom }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Transfer To</td>
      <td>: {{ remittanceDetail.transactionInformation?.currencyTo }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Amount</td>
      <td>: {{ remittanceDetail.transactionInformation?.amountOriginal }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Rate</td>
      <td>: {{ remittanceDetail.transactionInformation?.rate }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Total Remit</td>
      <td>: {{ remittanceDetail.transactionInformation?.totalRemitReceived }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Service Charge</td>
      <td>: {{ remittanceDetail.transactionInformation?.serviceCharge }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Total Amount</td>
      <td>: {{ remittanceDetail.transactionInformation?.totalAmount }}</td>
    </tr>
  </tbody>
</table>

<div style="width: 100%; height: 5px; border-bottom: 1px solid #000000; margin-bottom: 20px; margin-top: 20px;">
</div>

<table style="width: 100%; font-size: 9px !important;">
  <tr>
    <td colspan="2">
      <span style="font-size: 11px; font-weight: 700;">Remittance Receipt</span>
    </td>
  </tr>
  <tbody>
    <tr>
      <td style="width: 30%;">No.</td>
      <td>: {{ remittanceDetail.generalInformation?.remittanceNumber }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">GMT Date</td>
      <td>: {{ dateFormatService.longFormatDateWithTimeNoTz(remittanceDetail.generalInformation?.gmtDate) }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Local Date</td>
      <td>: {{ dateFormatService.longFormatDateWithTimeUsingTz(remittanceDetail.generalInformation?.localDate) }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Agent Reff</td>
      <td>: -</td>
    </tr>
    <tr>
      <td style="width: 30%;">Provider</td>
      <td>: {{ remittanceDetail.generalInformation?.provider.name }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Product Category</td>
      <td>: {{ remittanceDetail.generalInformation?.product.category }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Product</td>
      <td>: {{ remittanceDetail.generalInformation?.product.name }}</td>
    </tr>
  </tbody>
</table>
<table style="width: 100%; font-size: 9px !important;">
  <tbody>
    <tr>
      <td colspan="2">
        <span style="font-size: 11px; font-weight: 700;">Sender Information</span>
      </td>
    </tr>
    <tr>
      <td style="width: 30%;">Customer</td>
      <td>: {{ remittanceDetail.senderInformation?.cName }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Customer ID</td>
      <td>: {{ remittanceDetail.senderInformation?.cCode }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Address</td>
      <td>: {{ remittanceDetail.senderInformation?.cAddress }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Country</td>
      <td>: {{ remittanceDetail.senderInformation?.cCountry }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Phone</td>
      <td>: {{ remittanceDetail.senderInformation?.cPhone }}</td>
    </tr>
  </tbody>
</table>
<table style="width: 100%; font-size: 9px !important;">
  <tbody>
    <tr>
      <td colspan="2">
        <span style="font-size: 11px; font-weight: 700;">Beneficiary Information</span>
      </td>
    </tr>
    <tr>
      <td style="width: 30%;">Beneficiery</td>
      <td>: {{ remittanceDetail.beneficiaryInformation?.bName }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Phone</td>
      <td>: {{ remittanceDetail.beneficiaryInformation?.bPhone }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Destination Account</td>
      <td>: {{ remittanceDetail.beneficiaryInformation?.bAccountNo }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Bank</td>
      <td>: {{ remittanceDetail.beneficiaryInformation?.bank }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Branch</td>
      <td>: {{ remittanceDetail.beneficiaryInformation?.branch }}</td>
    </tr>
  </tbody>
</table>
<table style="width: 100%; font-size: 9px !important;">
  <tbody>
    <tr>
      <td colspan="2">
        <span style="font-size: 11px; font-weight: 700;">Transaction Information</span>
      </td>
    </tr>
    <tr>
      <td style="width: 30%;">Transfer From</td>
      <td>: {{ remittanceDetail.transactionInformation?.currencyFrom }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Transfer To</td>
      <td>: {{ remittanceDetail.transactionInformation?.currencyTo }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Amount</td>
      <td>: {{ remittanceDetail.transactionInformation?.amountOriginal }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Rate</td>
      <td>: {{ remittanceDetail.transactionInformation?.rate }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Total Remit</td>
      <td>: {{ remittanceDetail.transactionInformation?.totalRemitReceived }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Service Charge</td>
      <td>: {{ remittanceDetail.transactionInformation?.serviceCharge }}</td>
    </tr>
    <tr>
      <td style="width: 30%;">Total Amount</td>
      <td>: {{ remittanceDetail.transactionInformation?.totalAmount }}</td>
    </tr>
  </tbody>
</table>
