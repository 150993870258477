import { InquiryAddBeneficieryDto } from './../../shared/dto/customer/beneficieries';
import { InquiryBPJSDto, InquiryPaymentDigital } from './../../shared/dto/admin/remittance.dto';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonPaginateResponse, CommonResponse } from '../api-response.dto';
import { environment } from 'src/environments/environment';
import { RemittancePayloadDto, RemittanceResponseDto, InquiryBankTransferDto, ProcessCustomerTransferDto } from '../../shared/dto/admin/remittance.dto';
import { ConfirmationTransferPayloadDto, CustomerConfirmationTransferPayloadDto, RemittancePrePaidDto } from 'src/app/shared/dto/customer/transaction.dto';
import { Observable } from 'rxjs';
import { toFormData } from 'src/app/helpers/formdata.service';

@Injectable({
  providedIn: 'root',
})
export class RemittanceService {
  constructor(private http: HttpClient) {}

  public remittanceDetail: RemittanceResponseDto;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  };

  addRemittance(param: RemittancePayloadDto, isCustomer: boolean) {
    delete param.rateValue;
    delete param.bankName;
    delete param.mainProviderName;
    delete param.productName;
    if (!isCustomer) {
      return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance`,
        param,
        this.httpOptions
      );
    } else {
      return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance/customer`,
        param,
        this.httpOptions
      );
    }
  }

  addRemittancePulsaAndPaketData(param: RemittancePrePaidDto, isCustomer: boolean) {
    delete param.productName;
    delete param.mainProviderName;
    if (!isCustomer) {
      return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance`,
          param,
          this.httpOptions
        );
    } else {
      return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance/customer`,
        param,
        this.httpOptions
      );
    }
  }

  addRemittanceBpjs(param: RemittancePrePaidDto, isCustomer: boolean) {
    delete param.productName;
    delete param.mainProviderName;
    if (!isCustomer) {
      return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance`,
          param,
          this.httpOptions
        );
    } else {
      return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance/customer`,
        param,
        this.httpOptions
      );
    }
  }

  addRemittanceTokenPLN(param: RemittancePrePaidDto, isCustomer: boolean) {
    delete param.productName;
    delete param.mainProviderName;
    if (!isCustomer) {
      return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance`,
          param,
          this.httpOptions
        );
    } else {
      return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance/customer`,
        param,
        this.httpOptions
      );
    }
  }

  addRemittanceTelkom(param: RemittancePrePaidDto) {
    delete param.productName;
    delete param.mainProviderName;
    return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance`,
        param,
        this.httpOptions
      );
  }

  addRemittanceEMoney(param: RemittancePrePaidDto, isCustomer: boolean) {
    delete param.productName;
    delete param.mainProviderName;
    if (!isCustomer) {
      return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance`,
          param,
          this.httpOptions
        );
    } else {
      return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance/customer`,
        param,
        this.httpOptions
      );
    }
  }

  addProcessRemittanceCustomer(param: ProcessCustomerTransferDto) {
    return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance/process`,
        param,
        this.httpOptions
      );
  }

  addInquiryBankTransfer(param: InquiryBankTransferDto) {
    return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance/inquery/banktransfer`,
        param,
        this.httpOptions
      );
  }

  addInquiryPaymentDigital(param: InquiryPaymentDigital): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance/inquery/banktransfer`,
        param,
        this.httpOptions
      );
  }

  addInquiryEMoney(param: InquiryPaymentDigital) {
    return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance/inquery/banktransfer`,
        param,
        this.httpOptions
      );
  }

  addInquiryPLN(param: InquiryBPJSDto) {
    return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance/inquery/banktransfer`,
        param,
        this.httpOptions
      );
  }

  addInquiryPDAM(param: InquiryBPJSDto) {
    return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance/inquery/banktransfer`,
        param,
        this.httpOptions
      );
  }

  addCustomerInquiryBankTransfer(param: InquiryBankTransferDto) {
    return this.http.post<CommonResponse>(`${environment.apiUrl}/customer/inquery/banktransfer`,
        param,
        this.httpOptions
      );
  }

  getRemmitanceDetail(remmitUuid: string) {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/remittance/${remmitUuid}`, {
      params: {},
    });
  }

  getRemmitanceCustomerDetail(remmitUuid: string) {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/remittance/customer/${remmitUuid}`, {
      params: {},
    });
  }

  getRemittanceCustomerDetailByUniqueCode(uniqueCode: string) {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/remittance/scan-barcode/${uniqueCode}`, {
      params: {},
    });
  }

  getRemmitanceListPagination(param: any) {
    return this.http.get<CommonPaginateResponse>(`${environment.apiUrl}/remittance/pagination`, {
      params: param,
    });
  }

  getTransactionStatus() {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/transactionStatus`, {
      params: {},
    });
  }

  getCostumerTransactionHistory(param: any): Observable<CommonPaginateResponse> {
    return this.http.get<CommonPaginateResponse>(`${environment.apiUrl}/remittance/transaction-history/pagination`, {
      params: param,
    });
  }

  getCustomerRequestTransaction(param: any) {
    return this.http.get<CommonPaginateResponse>(`${environment.apiUrl}/remittance/customer/pagination`, {
      params: param,
    });
  }

  getRemittancePendingTransaction(param: any) {
    return this.http.get<CommonPaginateResponse>(`${environment.apiUrl}/remittance/transaction-history/pagination`, {
      params: param,
    });
  }

  addBeneficieryInquiry(param: InquiryAddBeneficieryDto) {
    return this.http.post<CommonResponse>(`${environment.apiUrl}/remittance/inquery/checkaccount`,
        param,
        this.httpOptions
      );
  }

  getConfirmationTransfer(param: any): Observable<CommonPaginateResponse> {
    return this.http.get<CommonPaginateResponse>(`${environment.apiUrl}/confirmtransfer/pagination`, {
      params: param,
    });
  }

  checkConfirmationTransfer(param: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/confirmtransfer`, {
      params: param,
    });
  }

  getConfirmationTransferDetail(uuid: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/confirmtransfer/${uuid}`, {
      params: {},
    });
  }

  getImageConfirmationTransfer(uuid: string) {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/confirmtransfer/getimage/${uuid}`, {
      params: {},
    });
  }

  submitConfirmTransfer(param: CustomerConfirmationTransferPayloadDto): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(environment.apiUrl + '/confirmtransfer', toFormData(param));
  }

  submitProcessConfirmTransfer(param: ConfirmationTransferPayloadDto, uuid: string) {
    return this.http.put<CommonResponse>(`${environment.apiUrl}/confirmtransfer/${uuid}`,
        param,
        this.httpOptions
      );
  }

  getTotalTransactionDoneBySender(param: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/remittance/totalremitbysender`, {
      params: param,
    });
  }

  getMostFrequestBasedCurrencyDoneBySender(param: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/remittance/mostfrequestbasedcurrencybysender`, {
      params: param,
    });
  }

  getBranchSenderMaxLimit(param: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/branchsenderlimit`, {
      params: param,
    });
  }

  getBlacklistChecking(param: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/blacklist/check`, {
      params: param,
    });
  }
}
